/** @format */

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import AdminTabLots from "../Component/Admin/Default/AdminTabLots/AdminTabLots";
import AdminDefaultDoc from "../Component/Admin/Default/AdminTabDoc/AdminTabDoc";
import { checkTokenExpiration } from "./RouterUtils";
import Cookies from "js-cookie";

export default function RouterUser() {
  const token = Cookies.get("Token");
  const role = Cookies.get("Role");

  useEffect(() => {
    // Vérifier périodiquement si le token a expiré (par exemple, toutes les minutes)
    const interval = setInterval(checkTokenExpiration, 60000); // Vérifier toutes les 60 secondes (60000 ms)

    return () => {
      clearInterval(interval); // Nettoyer l'intervalle à la suppression du composant
    };
  }, []);
  
  if (!token || role !== "superadmin") {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Routes>
        <Route path="/*" element={<AdminTabLots />} />
        <Route path="/Docs" element={<AdminDefaultDoc />} />
      </Routes>
    </>
  );
}
