import Cookies from "js-cookie";

const Prenom = Cookies.get("Username");
const Nom = Cookies.get("Family_name");

export const jsonOptions = [
    { name: 'Sélectionner un Type', value: {} },
    { name: 'CNI', value: { "TypeDoc": "CNI__", "prenom" : Prenom, "nom" : Nom } },
    { name: 'Releve d\'information', value: { "TypeDoc": "Releve information", "prenom" : Prenom, "nom" : Nom } },
    { name: 'IBAN/RIB', value: { "TypeDoc": "RIB__", "prenom" : Prenom, "nom" : Nom } },
    { name: 'Extrait kbis', value: { "TypeDoc": "Kbis_", "prenom" : Prenom, "nom" : Nom } },
    { name: 'Bulletin de salaire', value: { "TypeDoc": "Bulletin de salaire", "prenom" : Prenom, "nom" : Nom } },
    { name: 'Passeport', value: { "TypeDoc": "Passeport", "prenom" : Prenom, "nom" : Nom } },
    { name: 'Constat amiable', value: { "TypeDoc": "Constat amiable", "prenom" : Prenom, "nom" : Nom } },
    { name: 'Bilan', value: { "TypeDoc": "Bilan comptable", "prenom" : Prenom, "nom" : Nom } },
    { name: 'Facture', value: { "TypeDoc": "Facture", "prenom" : Prenom, "nom" : Nom } },
    { name: 'Carte grise', value: { "TypeDoc": "Carte grise", "prenom" : Prenom, "nom" : Nom } },
    { name: 'Rapport d\'expertise', value: { "TypeDoc": "Rapport expertise", "prenom" : Prenom, "nom" : Nom } }
  ];
  