import NavBar from "../../../Navbar/Navbar";
import AdminTabLotsMain from "./AdminTabLotsMain";

const AdminTabLots = () => {
  return (
    <>
      <NavBar />
      <div className="page-container" style={{ backgroundColor: "#F7F9F9", height: "90vh" }}>
        <AdminTabLotsMain />
      </div>
    </>
  );
};

export default AdminTabLots;
