/** @format */

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import Import from "../Component/Dashboard/Import/Import";
import DefaultLots from "../Component/Dashboard/Default/TabLots/TabLots";
import DefaultDocs from "../Component/Dashboard/Default/TabDoc/TabDoc";
import ModelsVideoCodage from "../Component/Dashboard/Default/VideoCodage/ModelsVideoCodage";
import UploadDoc from "../Component/Dashboard/Default/UploadDoc/UploadDoc";
import { checkTokenExpiration } from "./RouterUtils";

export default function RouterUser() {
  const token = Cookies.get("Token");
  const role = Cookies.get("Role");

  useEffect(() => {
    // Vérifier périodiquement si le token a expiré (par exemple, toutes les minutes)
    const interval = setInterval(checkTokenExpiration, 60000); // Vérifier toutes les 60 secondes (60000 ms)

    return () => {
      clearInterval(interval); // Nettoyer l'intervalle à la suppression du composant
    };
  }, []);

  if (!token || role !== "user") {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <>
              <DefaultLots />{" "}
            </>
          }
        />
        {
          // <Route path='/Model' element={<ModelSelector />} />
        }{" "}
        <Route path="/Lots" element={<DefaultLots />} />
        <Route path="/Docs" element={<DefaultDocs />} />
        <Route path="/Import" element={<Import />} />
        <Route path="/ModelsVideoCodage" element={<ModelsVideoCodage />} />
        <Route path="/UploadDoc" element={<UploadDoc />} />
      </Routes>
    </>
  );
}
