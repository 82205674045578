import Cookies from 'js-cookie';

export const checkTokenExpiration = () => {
  const token = Cookies.get("Token");
  if (token) {
    const tokenExpirationTime = new Date(
      JSON.parse(atob(token.split(".")[1])).exp * 1000
    );
    const currentTime = new Date();


    if (currentTime > tokenExpirationTime) {
      Cookies.remove("Token");
      localStorage.clear();
      window.location.href = "/"; 
    }
  }
};
