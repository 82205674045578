import AdminTabLotsBase from "../Admin/Default/AdminTabLots/AdminTabLotsBase";

// Associez les modèles à leurs composants de tableaux spécifiques
const modelTableComponents = {
  //   weexa: WeexaTable,
  // Ajoutez d'autres modèles ici
};

// Associez les clients à leurs composants de tableaux spécifiques
const clientTableComponents = {
//   clientA: ClientATable,
  // Ajoutez d'autres clients ici
};

const getTableComponent = (selectedModel, selectedClient) => {
  // Vérifier si un tableau spécifique existe pour le modèle
  if (modelTableComponents[selectedModel]) {
    return modelTableComponents[selectedModel];
  }
  // Vérifier si un tableau spécifique existe pour le client
  if (clientTableComponents[selectedClient]) {
    return clientTableComponents[selectedClient];
  }
  // Retourner le tableau par défaut
  return AdminTabLotsBase;
};

export default getTableComponent;
