import React from "react";
import { useTranslation } from "react-i18next";

const Pagination = ({
  filteredData,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const maxVisiblePages = 9;
  let startPage, endPage;

  if (totalPages <= maxVisiblePages) {
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= 5) {
    startPage = 1;
    endPage = maxVisiblePages - 1;
  } else if (currentPage >= totalPages - 4) {
    startPage = totalPages - (maxVisiblePages - 2);
    endPage = totalPages;
  } else {
    startPage = currentPage - 4;
    endPage = currentPage + 4;
  }

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", justifyContent: "center"}}>
      {totalPages >= 10 && (
        <button
          className="btn btn-outline-primary btn-sm me-2"
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
        >
          {t("First")}
        </button>
      )}
      {pages.map((page) => (
        <button
          key={page}
          className="btn btn-outline-primary btn-sm me-2"
          onClick={() => setCurrentPage(page)}
          style={{
            fontWeight: currentPage === page ? "bold" : "normal",
            backgroundColor: currentPage === page ? "#0d6efd" : "transparent",
            color: currentPage === page ? "white" : "#0d6efd",
          }}
        >
          {page}
        </button>
      ))}
      {totalPages >= 10 && (
        <button
          className="btn btn-outline-primary btn-sm me-2"
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
        >
          {t("Last")}
        </button>
      )}
    </div>
  );
};

export default Pagination;
