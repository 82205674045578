import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import mainlogo from "../../IMG/media/LOGOTYPES/MyActivHub/Web/PNG/logoType.png";
import backgroundIMG from "./../../IMG/media/ICONOGRAPHIE/IMAGES/background.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { togglePasswordVisibility, handlePasswordChange } from "./LoginUtils";
import { loginAdmin } from "../../AdminAPI/AuthAdmin";

export default function LoginAdmin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [mfa, setMFA] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    console.log(mfa);
    if (
      !username ||
      !password ||
      username === "" ||
      password === "" ||
      username === undefined ||
      password === undefined
    ) {
      setErrorMessage("Please enter both username and password.");
    } else {
      loginAdmin(username, password, mfa)
        .then((data) => {
          const token = data.idToken;
          if (token) {
            navigate("/Admin/");
          } else {
            setErrorMessage("Something went wrong during the login process.");
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorMessage("An error occurred while logging in.");
        });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundImage: `url(${backgroundIMG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="text-center"
        style={{
          width: "18rem",
          margin: "auto",
          marginTop: "120px",
        }}
      >
        <div className="">
          <img
            src={mainlogo}
            alt="logo"
            style={{ width: "240px", height: "auto" }}
          />
        </div>
        <br />
        <br></br>
        <div>
          <form
            onSubmit={handleLogin}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <p
              htmlFor="password"
              className="d-flex justify-content-start"
              style={{ alignSelf: "start", margin: 0, fontSize: "14px" }}
            >
              Utilisateur <span className="text-danger">*</span>
            </p>
            <div className="form-group">
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{ width: "320px", borderRadius: "50px" }}
                className="form-control input-common-style"
              />
            </div>
            <br />
            <p
              htmlFor="password"
              className="d-flex justify-content-start"
              style={{ alignSelf: "start", margin: 0, fontSize: "14px" }}
            >
              motdepasse <span className="text-danger">*</span>
            </p>
            <div className="form-group">
              <label style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="form-control input-common-style"
                  name="password"
                  style={{ width: "320px", borderRadius: "50px" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="password-icon">
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    onClick={() =>
                      togglePasswordVisibility(setShowPassword, showPassword)
                    }
                    style={{
                      fontSize: "20px",
                      color: "#868686",
                      position: "absolute",
                      top: "50%",
                      right: "20px",
                      transform: "translateY(-50%)",
                      transition: "color 0.2s",
                    }}
                  />
                </div>
              </label>
            </div>
            <p
              htmlFor="mfa"
              className="d-flex justify-content-start"
              style={{ alignSelf: "start", margin: 0, fontSize: "14px" }}
            >
              MFA Code
            </p>
            <div className="form-group">
              <input
                type="text"
                id="mfa"
                value={mfa}
                onChange={(e) => setMFA(e.target.value)}
                style={{ width: "320px", borderRadius: "50px" }}
                className="form-control input-common-style"
              />
            </div>
            <br />
            <Link to="/">Login as User</Link>

            <hr style={{ width: "100%", margin: "20px 0" }} />

            <button
              type="submit"
              to="/Admin/"
              className="btn btn-primary btn-block"
              style={{
                width: "250px",
                marginTop: "15px",
                borderRadius: "10px",
              }}
            >
              connexion
            </button>
          </form>

          {errorMessage && (
            <div className="alert alert-danger mt-3" role="alert">
              <strong>Error:</strong> {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
