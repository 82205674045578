/** @format */

import process from "process";
const API_BASE_URL = process.env.REACT_APP_API_URL; // Remplacez par l'URL de base de votre API
let docViewWindow = null;

export async function docViewADM(token, docId, modelId, instanceId, clientId) {
  // Validation des paramètres pour s'assurer qu'ils ne sont pas undefined
  if (!token || !docId || !modelId || !instanceId) {
    console.error("Un ou plusieurs paramètres requis sont undefined", {
      token,
      docId,
      modelId,
      instanceId,
    });
    throw new Error("Un ou plusieurs paramètres requis sont undefined");
  }

  const apiUrl = `${API_BASE_URL}/admin/document/view`;
  console.log( "instanceid admin : ", instanceId);
  console.log("modelid admin : ", modelId);
  console.log("docId admin : ", docId);
  console.log("ClientId : ", clientId)

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        InstanceId: instanceId,
        ModelId: modelId,
        Duration: 600,
        DocumentId: docId,
        ClientId: clientId
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);

    const presignedUrl = data.message.PresignedURL;
    if (presignedUrl) {
      const windowFeatures =
        "width=600,height=400,resizable=yes,scrollbars=yes,status=yes";
      if (docViewWindow && !docViewWindow.closed) {
        docViewWindow.location.href = presignedUrl;
      } else {
        docViewWindow = window.open(presignedUrl, "_blank", windowFeatures);
      }
      docViewWindow.focus();
    }
    console.log("ok", presignedUrl)
    return presignedUrl;

  } catch (error) {
    throw new Error(error.message);
  }
}
