import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import fetchModel from "../../../../AdminAPI/ModelADM";
import fetchClient from "../../../../AdminAPI/ClientList";
import getTableComponent from "../../../Utils/GetTableComponent";
import config from "./config.json";

const AdminTabLotsMain = () => {
  const [selectedModel, setSelectedModel] = useState(
    localStorage.getItem("selectedModel") || ""
  );
  
  const [selectedClient, setSelectedClient] = useState(
    localStorage.getItem("selectedClient") || ""
  );
  
  const [models, setModels] = useState([]);
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const token = Cookies.get("Token");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const modelsData = await fetchModel(null, token);
        const filteredModels = modelsData
          .map((model) => ({
            id: model.ModelId,
            name: model.ModelName,
            component: model.ModelComponent,
          }))
          .filter((model) => config.modelesSelectionnes.includes(model.id));

        setModels(filteredModels);
      } catch (error) {
        console.error("Erreur lors de la récupération des modèles:", error);
      }
    };

    const fetchClients = async () => {
      try {
        const clientsData = await fetchClient(token);
        setClients(
          clientsData.filter((client) =>
            config.clientsSelectionnes.includes(client.ClientId)
          )
        );
      } catch (error) {
        console.error("Erreur lors de la récupération des clients:", error);
      }
    };

    fetchModels();
    fetchClients();
  }, [token]);

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    if (clientId === "") {
      setSelectedClient(null);
      setSelectedModel("");
      localStorage.removeItem("selectedClient");
      localStorage.removeItem("selectedModel");
    } else {
      setSelectedClient(clientId);
      setSelectedModel("");
      Cookies.set("Client", clientId);
      localStorage.setItem("selectedClient", clientId);
      localStorage.removeItem("selectedModel");
    }
  };
  
  const handleModelChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
  
    if (selectedValue.id) {
      Cookies.set("modelId", selectedValue.id);
      localStorage.setItem("selectedModel", selectedValue.id);
      setSelectedModel(selectedValue.id);
      setSelectedClient(null);
      localStorage.removeItem("selectedClient");
      navigate(`/Admin/${selectedValue.component}Lots`);
    } else {
      Cookies.set("modelId", "");
      localStorage.removeItem("selectedModel");
      setSelectedModel("");
      setSelectedClient(null);
      localStorage.removeItem("selectedClient");
      navigate("/Admin/");
    }
  };
  

  const SelectedTable = useMemo(
    () => getTableComponent(selectedModel, selectedClient),
    [selectedModel, selectedClient]
  );

  useEffect(() => {
    const storedModel = localStorage.getItem("selectedModel");
    const storedClient = localStorage.getItem("selectedClient");
  
    if (storedModel) {
      setSelectedModel(storedModel);
    }
  
    if (storedClient) {
      setSelectedClient(storedClient);
    }
  }, []);
  

  return (
    <div>
      <div style={{ display: "flex" }}>
        {/* Sélection du client */}
        {!selectedModel && clients.length > 0 ? (
          <select
            className="form-select"
            onChange={handleClientChange}
            style={{
              width: "auto",
              height: "30px",
              fontSize: "14px",
              marginTop: "4px",
              marginLeft: "5px",
              backgroundColor: "#F7F9F9",
              color: "#235073",
              fontWeight: "bold",
            }}
          >
            <option value="">Tous les clients</option>
            {clients.map((client) => (
              <option key={client.ClientId} value={client.ClientId}>
                {client.ClientId}
              </option>
            ))}
          </select>
        ) : null}

        {/* Sélection du modèle */}
        {!selectedClient &&
        models.length > 0 &&
        models.some((model) => model.component !== undefined) ? (
          <select
            className="form-select"
            onChange={handleModelChange}
            style={{
              width: "auto",
              height: "30px",
              fontSize: "14px",
              marginTop: "4px",
              marginLeft: "5px",
              backgroundColor: "#F7F9F9",
              color: "#235073",
              fontWeight: "bold",
            }}
          >
            <option value="{}">{t("AllModels")}</option>
            {models
              .filter((model) => model.component !== undefined)
              .map((model) => (
                <option
                  key={model.id}
                  value={JSON.stringify({
                    id: model.id,
                    component: model.component,
                    name: model.name,
                  })}
                >
                  {model.name}
                </option>
              ))}
          </select>
        ) : null}
      </div>

      <div>
        {!selectedClient && !selectedModel ? (
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              textAlign: "center",
            }}
          >
            Choisir un model ou un client.
          </h1>
        ) : (
          <div key={`${selectedModel}-${selectedClient}`}>
            <SelectedTable />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminTabLotsMain;
