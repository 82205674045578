import { React, useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import logo_ah from "../../IMG/media/LOGOTYPES/MyActivHub/Web/PNG/logoType_light.png";
import weexa from "../../IMG/weexa.png.webp";
import afer from "../../IMG/afer.png";
import IRM from "../../IMG/IMF.png";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./nav.css";
import process from "process";
import { setEnvironmentColors } from "../Utils/EnvironnementColor";
import { useTranslation } from "react-i18next";
import config from "../config.json";

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [headerColor, setHeaderColor] = useState("#235073");
  const navigate = useNavigate();
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    navigate("/");
  };

  const Nom = Cookies.get("Username");
  const Family_name = Cookies.get("Family_name");
  const Role = Cookies.get("Role");

  useEffect(() => {
    setEnvironmentColors([[setHeaderColor, "headerColor"]]);
  }, []);

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  const userLogos = {
    Invoices: weexa,
    Classification: afer,
  };

  const logo = userLogos[Family_name];
  const environment = process.env.REACT_APP_DEV_NAME;
  
  return (
    <div className="nav-container">
      <Navbar
        dark
        className="custom-navbar"
        style={{ backgroundColor: headerColor, marginBottom: 0 }}
      >
        <NavbarToggler onClick={toggle} className="mr-2" />
        <div
          style={{ color: "white", display: "flex", alignItems: "flex-end" }}
        >
          <img
            src={config["IMF-DEV"] ? IRM : logo_ah}
            alt="Logo"
            className="logo"
            style={{ height: "30px", width: "auto", marginRight: "10px" }}
          />
          <div style={{ fontWeight: "bold", marginBottom: "0px" }}>
            {process.env.REACT_APP_DEV_NAME}
          </div>
        </div>
        <div className="user-info ml-auto d-flex align-items-center">
          <UncontrolledDropdown setActiveFromChild>
            <DropdownToggle
              tag="a"
              className="nav-link d-flex align-items-center"
              caret
            >
              <div>
                {logo ? (
                  <img
                    src={logo}
                    alt={`${Nom} logo`}
                    style={{ height: "24px", marginRight: "8px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{ height: "24px", marginRight: "8px" }}
                  />
                )}
              </div>
              <font style={{ color: "white" }}>
                {Nom} {Family_name}
              </font>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={handleLogout}>{t("LogOut")}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div
          className={`sidebar ${isOpen ? "open" : ""}`}
          style={{ backgroundColor: headerColor }}
        >
          <Nav navbar vertical style={{ width: "100%" }}>
            {Role != "superadmin" ? (
              <>
                <NavItem className="nav_item_position" data-type="premier">
                  <NavLink className="text-white" href="/User/">
                    {t("Home")}
                  </NavLink>
                </NavItem>
                <NavItem className="nav_item_position">
                  <NavLink className="text-white" href="/User/Import">
                    Import
                  </NavLink>
                </NavItem>
                {environment == "DEV" && (
                  <NavItem className="nav_item_position">
                    <NavLink className="text-white" href="/User/UploadDoc">
                      Upload Doc
                    </NavLink>
                  </NavItem>
                )}
                <NavItem className="nav_item_position">
                  <NavLink
                    className="text-white"
                    href="/User/ModelsVideoCodage"
                  >
                    {t("models video coding")}
                  </NavLink>
                </NavItem>
              </>
            ) : (
              <>
                <NavItem className="nav_item_position" data-type="premier">
                  <NavLink className="text-white" href="/Admin/">
                    {t("Home")}
                  </NavLink>
                </NavItem>
                <NavItem className="nav_item_position">
                  <NavLink className="text-white" href="/Admin/Import">
                    Import
                  </NavLink>
                </NavItem>
                {environment == "DEV" && (
                  <NavItem className="nav_item_position">
                    <NavLink className="text-white" href="/Admin/UploadDoc">
                      Upload Doc
                    </NavLink>
                  </NavItem>
                )}
                <NavItem className="nav_item_position">
                  <NavLink
                    className="text-white"
                    href="/Admin/ModelsVideoCodage"
                  >
                    {t("models video coding")}
                  </NavLink>
                </NavItem>
              </>
            )}
            <NavItem>
              <div className="nav_item_position text-white pt-2">
                <label>
                  {t("choose a language")}
                  <select
                    onChange={(e) => changeLanguage(e.target.value)}
                    className="ms-2"
                    value={localStorage.getItem("i18nextLng") || ""}
                  >
                    <option value="fr">Fr</option>
                    <option value="en">En</option>
                  </select>
                </label>
              </div>
            </NavItem>
          </Nav>
        </div>
      </Navbar>
    </div>
  );
}
