import { Navbar } from "reactstrap";
import { setEnvironmentColors } from "../../../Utils/EnvironnementColor";
import { createInstanceAndDocs } from "../../../../API/UploadToS3";
import Cookies from "js-cookie";
import NavBar from "../../../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jsonOptions } from "./JsonOptions";
import "./uploadDoc.css";
import styles from "../DefaultCSS.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Modal, Spinner } from "reactstrap";
import { getModel } from "../../../../API/ModelList";
import { useTranslation } from "react-i18next";

const UploadDoc = () => {
  const [Documents, setDocuments] = useState([]);
  const [SelectedJsons, setSelectedJsons] = useState({});
  const today = new Date();
  const formatedDate = today.toISOString().split("T")[0];
  const idToken = Cookies.get("Token");
  const navigate = useNavigate();
  const Prenom = Cookies.get("Username");
  const Nom = Cookies.get("Family_name");
  const [colorFilter, setcolorFilter] = useState("#235073");
  const [colorFilterAction, setcolorFilterAction] = useState("#ffffff");
  const [Error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [ModelList, setModelList] = useState([]);

  //Récupère les différents modèles
  useEffect(() => {
    getModel(idToken)
      .then((data) => {
        setModelList(data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des modèles", error);
      });
  }, []);

  //Créer une instance avec les docs dans le tableau
  const createinstancefunction = async () => {
    if (Documents.length === 0) {
      setError("Veuillez ajouter au moins un Documents.");
      return;
    }
    const instanceName = `${Prenom} ${Nom} ${formatedDate}`;
    const modelId = "ah-dev-class01";
    const expectedDocs = Documents.length;
    const token = idToken;
    const sFinalCustomer = "AFER";
    try {
      setLoading(true);
      const { instanceId, instanceName: returnedInstanceName } =
        await createInstanceAndDocs(
          instanceName,
          modelId,
          Documents.map((doc) => doc.file),
          token,
          true,
          Documents.map((doc, index) => SelectedJsons[index] || {}),
          expectedDocs,
          sFinalCustomer
        );
      Cookies.set("instanceId", instanceId); // Ajout d'une expiration (facultatif)
      Cookies.set("instanceName", returnedInstanceName);
      setLoading(false);
      navigate(`/User/Docs`);
    } catch (error) {
      setLoading(false);
      console.error("Erreur lors de la création de l'instance : ", error);
      setError("Erreur lors de la création de l'instance : ");
    }
  };

  //Permet d'ajouter un document et vérifie si le doc est bon
  const addDocuments = (event) => {
    const files = Array.from(event.target.files);
    const supportedFileTypes = getSupportedFileTypes("ah-dev-class01");

    const validFiles = files.filter((file) =>
      supportedFileTypes.includes(file.name.split(".").pop().toLowerCase())
    );

    if (validFiles.length !== files.length) {
      setError("Certains fichiers ne sont pas pris en charge.");
    }

    const newDocs = validFiles.map((file) => ({ name: file.name, file }));
    setDocuments((prevDocs) => [...prevDocs, ...newDocs]);
  };

  //permet de drag and drop un doc et vérifie si le doc est bon
  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const supportedFileTypes = getSupportedFileTypes("ah-dev-class01");

    const validFiles = files.filter((file) =>
      supportedFileTypes.includes(file.name.split(".").pop().toLowerCase())
    );

    if (validFiles.length !== files.length) {
      setError("Certains fichiers ne sont pas pris en charge.");
    }

    const newDocs = validFiles.map((file) => ({ name: file.name, file }));
    setDocuments((prevDocs) => [...prevDocs, ...newDocs]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  //retirer un document de la liste ainsi que le json
  const removeDocument = (index) => {
    const newDocuments = Documents.filter((_, i) => i !== index);
    setDocuments(newDocuments);
    const newSelectedJsons = { ...SelectedJsons };
    delete newSelectedJsons[index];
    setSelectedJsons(newSelectedJsons);
  };

  //Permet d'attribuer une valeur au json en fonction du select
  const handleJsonChange = (index, value) => {
    setSelectedJsons({ ...SelectedJsons, [index]: value });
  };

  //Récupère les couleurs dans le fichier config
  useEffect(() => {
    setEnvironmentColors([
      [setcolorFilter, "colorFilter"],
      [setcolorFilterAction, "colorFilterAction"],
    ]);
  }, []);

  //Prends les types de doc supportés en fonction du modèle
  const getSupportedFileTypes = (modelId) => {
    const model = ModelList.find((m) => m.ModelId === modelId);
    return model ? model.ModelSupportedFileTypes : [];
  };

  // Permet de faire la traduction de la page
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  return (
    <>
      <NavBar />
      <div className="page-container" style={{ backgroundColor: "#F7F9F9" }}>
        {!loading && (
          <div>
            <h1>{t("Customer area")}</h1>
            <h4 style={{ textAlign: "left", marginLeft: "1vw" }}>
              {t("Upload your documents here")} :{" "}
            </h4>

            <div
              className="table-responsive"
              style={{
                padding: "20px",
                borderRadius: "5px",
                overflowX: "auto",
                lineHeight: "0.7",
                width: "50vw",
                margin: "auto",
              }}
            >
              <div style={{ height: "300px", overflowY: "auto" }}>
                <table
                  className="table-striped"
                  style={{
                    tableLayout: "fixed",
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        height: "25px",
                        verticalAlign: "middle",
                        backgroundColor: colorFilter,
                      }}
                    >
                      <th style={{ ...styles.TabDoc, width: "200px" }}>
                        {t("File name")}
                      </th>
                      <th style={{ ...styles.TabDoc, width: "200px" }}>Type</th>
                      <th style={{ ...styles.TabDoc, width: "200px" }}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Documents.map((doc, index) => (
                      <tr
                        key={index}
                        style={{ fontSize: "12px", verticalAlign: "middle" }}
                      >
                        <td>{doc.name}</td>
                        <td>
                          <select
                            className="form-control"
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              height: "25px",
                              width: "100%",
                              padding: "0px",
                              verticalAlign: "middle",
                            }}
                            onChange={(e) =>
                              handleJsonChange(
                                index,
                                jsonOptions[e.target.selectedIndex].value
                              )
                            }
                          >
                            {jsonOptions.map((json, jsonIndex) => (
                              <option key={jsonIndex} value={json.name}>
                                {json.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <button
                            className="btn"
                            onClick={() => removeDocument(index)}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>{t("New document")}</td>
                      <td>{t("Type of doc")}</td>
                      <td>
                        <button
                          className="btn"
                          onClick={() =>
                            document.getElementById("addDoc").click()
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                        <input
                          id="addDoc"
                          type="file"
                          multiple
                          onChange={addDocuments}
                          style={{ display: "none" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ padding: "10px" }}>
              <button
                className="btn"
                style={{ border: "1px solid black" }}
                onClick={createinstancefunction}
              >
                {t("Create instance")}
              </button>
            </div>
            <div style={{ color: "red" }}>{Error}</div>
          </div>
        )}
        {loading && (
          <Modal isOpen={true} centered>
            <div className="modal-body text-center">
              <Spinner color="primary" />
              <h4>{t("Processing in progress...")}</h4>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default UploadDoc;
