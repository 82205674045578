import apiConfig from "../config/_config"; // api.js

async function fetchModel(clientId, token) {
  const apiUrl = `${apiConfig}/admin/model/list`;

  try {
    const body = clientId ? { ClientId: clientId } : {};

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.message.list;
  } catch (error) {
    console.error("Error fetching documents:", error);
    throw error;
  }
}

export default fetchModel;
