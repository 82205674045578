/** @format */
import apiConfig from "../config/_config"; // api.js
import process from "process";

const API_BASE_URL = process.env.REACT_APP_API_URL; // Remplacez par l'URL de base de votre API

export async function getInstance(token, modelId = null) {
  const apiUrl = `${API_BASE_URL}/process/list/full`;

  // Créer un objet body avec ModelId uniquement s'il est fourni
  const body = modelId ? { ModelId: modelId } : {};
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Assurez-vous que le Content-Type est JSON pour les requêtes POST
        Authorization: token,
      },
      body: JSON.stringify(body), // Convertir l'objet en JSON
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.message.list;
  } catch (error) {
    throw new Error(error);
  }
}
