import apiConfig from "../config/_config";
import Cookies from 'js-cookie';

export async function loginAdmin(username, password, MFA) {
    const loginApiUrl = `${apiConfig}/admin/user/login`;
    const userInfoApiUrl = `${apiConfig}/admin/user/get`;
    const loginData = {
        username: username,
        password: password,
        mfa : MFA
    };

    try {
        const loginResponse = await fetch(loginApiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/usern',
            },
            body: JSON.stringify(loginData),
        });

        if (!loginResponse.ok) {
            throw new Error('Login request failed with status ' + loginResponse.status);
        }
        else{
            const loginResult = await loginResponse.json();
            console.log(loginResult);

            Cookies.set('Token', loginResult.message.idToken, {
                secure: true
            });

            const idToken = loginResult.message.idToken;
            const userInfoResponse = await fetch(userInfoApiUrl, {
                method: 'GET',
                headers: {
                    Authorization: idToken,
                },
            });

            const userInfo = await userInfoResponse.json();
            console.log("userinfo : ", userInfo);

            Cookies.set("Username", userInfo.message.name);
            Cookies.set("Family_name", userInfo.message.family_name);
            Cookies.set("Role", userInfo.message.role);

            return { userInfo: userInfo.message.client, idToken: idToken };
        }
    } catch (error) {
        throw new Error('An error occurred during login.');
    }
}
