/** @format */
import { useEffect, useState } from "react";
import fetchModel from "../../../../AdminAPI/ModelADM";
import { getInstance } from "../../../../AdminAPI/InstancesADM";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "../DefaultCSS.json";
import { setEnvironmentColors } from "../../../Utils/EnvironnementColor";
import {
  DownloadCSVFile,
  DownloadCSVDoc,
} from "../../../UtilsAdmin/FileDownloadManager";
import {
  applyFilters,
  filterByModel,
  handleResetFilters,
} from "../../../Utils/FiltersManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortUp,
  faSortDown,
  faSearch,
  faCopy,
  faArrowRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { formatCustomDate, parseCustomDate } from "../../../Utils/DateManager";
import { sortData, toggleSort, applySort } from "../../../Utils/SortManager";
import { handleCopy } from "../../../Utils/CopyDocID";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LotStat from "../../../Dashboard/Default/LotStat/LotStat";
import DatePicker from "react-datepicker";
import fetchClient from "../../../../AdminAPI/ClientList";
import Pagination from "../../../Utils/Pagination";

const AdminTabLotsBase = () => {
  const savedFiltersString = localStorage.getItem("filters");
  let savedFilters = {
    sInstanceName: "",
    sStatus: "",
    sFinalCustomer: "",
    dCreationDate: null,
    iCreatedDocs: null,
    iExpectedDocs: null,
    iUploadedDocs: null,
    iCompletedDocs: null,
  };
  if (savedFiltersString) {
    try {
      savedFilters = JSON.parse(savedFiltersString);
    } catch (error) {
      console.error("Error parsing saved filters from localStorage", error);
    }
  }
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const token = Cookies.get("Token");
  const modelselected = Cookies.get("modelId");
  const [models, setModels] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedModel, setSelectedModel] = useState(
    localStorage.getItem("selectedModel") || ""
  );
  const [selectedClient, setSelectedClient] = useState(
    localStorage.getItem("selectedClient") || null
  );
  const { t } = useTranslation();
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("dCreationDate");
  const [filter, setFilter] = useState(savedFilters);
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [uniqueModels, setUniqueModels] = useState([]);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: null,
    toDate: null,
    filterType: "between",
  });
  const [colorFilter, setcolorFilter] = useState("#235073");
  const [colorFilterAction, setcolorFilterAction] = useState("#ffffff");
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const clientIdFromStorage = localStorage.getItem("selectedClient");
  const modelIdFromStorage = localStorage.getItem("selectedModel")

  const DatePickerStyle = {
    width: "400px",
    height: "200px",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    background: "white",
    border: "10px solid #183152",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  //Récupération des différents modèles existants
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const modelsData = await fetchModel(null, token);
        setModels(
          modelsData.map((model) => ({
            id: model.sModelId,
            name: model.ModelName,
            component: model.ModelComponent,
          }))
        );
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, [token]);

  useEffect(() => {
    const fetchInstance = async () => {
      try {
        if (!selectedClient && !selectedModel) {
          setData([]);
          return;
        }

        const responseData = await getInstance(
          selectedClient,
          selectedModel,
          token
        );

        if (
          responseData?.message?.list &&
          Array.isArray(responseData.message.list)
        ) {
          setData(responseData.message.list);
          const loadedStatuses = responseData.message.list.map(
            (item) => item.sStatus
          );
          setUniqueStatuses([...new Set(loadedStatuses)]);
          const loadedModels = responseData.message.list.map(
            (item) => item.sModelId
          );
          setUniqueModels([...new Set(loadedModels)]);
        } else {
          console.error("Unexpected response format:", responseData);
          setData([]);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des instances:", error);
        setData([]);
      }
    };

    fetchInstance();
  }, [selectedClient, selectedModel, token]);

  //Change les couleurs en fonction de l'environnement
  useEffect(() => {
    setEnvironmentColors([
      [setcolorFilter, "colorFilter"],
      [setcolorFilterAction, "colorFilterAction"],
    ]);
  }, []);

  // Charger les filtres sauvegardés depuis le localStorage
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem("filters"));
    if (savedFilters) {
      setFilter(savedFilters);
    }
  }, []);

  // Appliquer les filtres et mettre à jour filteredData
  useEffect(() => {
    const dataAfterFilters = applyFilters(
      data,
      filter,
      dateRangeFilter,
      parseCustomDate
    );
    const dataAfterModelFilter = filterByModel(dataAfterFilters, filter);
    setFilteredData(dataAfterModelFilter);
  }, [data, filter, dateRangeFilter, parseCustomDate]);

  //Appliquer le tri de lorsque le champ de l'ordre de  tri change
  useEffect(() => {
    if (sortField === "dCreationDate") {
      setData((prevData) => sortData([...prevData], sortField, sortOrder));
    } else {
      setData((prevData) => applySort([...prevData], sortField, sortOrder));
    }
  }, [sortField, sortOrder]);

  // Sauvegarder les filtres dans le localStorage
  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filter));
  }, [filter]);

  //Gérer le changement de modèle sélectionné
  useEffect(() => {
    const fetchClients = async () => {
      try {
        let clientsData;
        if (selectedModel === null || !selectedModel) {
          clientsData = await fetchClient(token, null);
        } else {
          clientsData = await fetchClient(token, selectedModel);
        }
        setClients(clientsData);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, [token, selectedModel]); // Relance quand selectedModel change

  //fonction pour basculer la visibilité
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  //Gérer le changement de tri
  const handleSort = (field) => {
    toggleSort(field, sortField, setSortField, sortOrder, setSortOrder);
  };

  return (
    <>
      <div
        className="page-container"
        style={{ backgroundColor: "#F7F9F9", height: "90vh" }}
      >
        <div className="div_ariane_stats">
          <div style={{ display: "flex", alignItems: "left" }}>
            <h6
              style={{
                marginTop: "10px",
                color: "#235073",
              }}
            >
              Instances
            </h6>
            <p style={{ margin: "10px 5px 0px 5px" }}>{" > "}</p>
            <h6 style={{ marginTop: "10px", width : "10vw", display : "flex"}}>{clientIdFromStorage || modelIdFromStorage}</h6>
          </div>
          {data.length > 0 && (
            <LotStat
              lots={applySort(
                applyFilters(data, filter, dateRangeFilter, parseCustomDate)
              )}
            />
          )}
        </div>{" "}
        {/* Partie invisible du site */}
        {isVisible && (
          <div style={DatePickerStyle}>
            <table style={{ width: "300px" }}>
              <tbody>
                <tr>
                  <td style={{ width: "100px", textAlign: "left" }}>
                    <label>Date de début :</label>
                  </td>
                  <td style={{ width: "100px" }}>
                    <DatePicker
                      selected={dateRangeFilter.fromDate}
                      onChange={(date) =>
                        setDateRangeFilter({
                          ...dateRangeFilter,
                          fromDate: date,
                        })
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "100px", textAlign: "left" }}>
                    <label>Date de fin :</label>
                  </td>
                  <td style={{ width: "100px" }}>
                    <DatePicker
                      selected={dateRangeFilter.toDate}
                      onChange={(date) =>
                        setDateRangeFilter({ ...dateRangeFilter, toDate: date })
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      height: "50px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <center>
                      <button onClick={toggleVisibility}>Fermer</button>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {/* Partie invisible du site */}
        {isVisible && (
          <div style={DatePickerStyle}>
            <table style={{ width: "300px" }}>
              <tbody>
                <tr>
                  <td style={{ width: "100px", textAlign: "left" }}>
                    <label>Date de début :</label>
                  </td>
                  <td style={{ width: "100px" }}>
                    <DatePicker
                      selected={dateRangeFilter.fromDate}
                      onChange={(date) =>
                        setDateRangeFilter({
                          ...dateRangeFilter,
                          fromDate: date,
                        })
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "100px", textAlign: "left" }}>
                    <label>Date de fin :</label>
                  </td>
                  <td style={{ width: "100px" }}>
                    <DatePicker
                      selected={dateRangeFilter.toDate}
                      onChange={(date) =>
                        setDateRangeFilter({ ...dateRangeFilter, toDate: date })
                      }
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{
                      height: "50px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <center>
                      <button onClick={toggleVisibility}>Fermer</button>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {data.length > 0 && selectedModel != "all" ? (
          <div
            className="table-responsive"
            style={{
              padding: "20px",
              borderRadius: "5px",
              overflowX: "auto",
              lineHeight: "0.7",
            }}
          >
            <table className="table table-striped">
              <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                <tr
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    height: "25px",
                    backgroundColor: colorFilter,
                    borderBottom: "none",
                  }}
                >
                  <th style={styles.tabLots}>Actions</th>
                  <th style={styles.tabLots}>{t("Models")}</th>
                  <th colSpan="2" style={styles.tabLots}>
                    Instance
                  </th>
                  <th style={styles.tabLots}>
                    <label onClick={toggleVisibility}>Création</label>
                  </th>
                  <th style={styles.tabLots}>{t("Status")}</th>
                  <th style={styles.tabLots}>{t("End customer")}</th>
                  <th style={styles.tabLots}>{t("Docs expected")}</th>
                  <th style={styles.tabLots}>{t("Docs created")}</th>
                  <th style={styles.tabLots}>{t("Docs uploaded")}</th>
                  <th style={styles.tabLots}>{t("Docs completed")}</th>
                </tr>
              </thead>
              <tbody style={{ overflowY: "auto" }}>
                {/* Deuxième header avec les différentes fonctions qui permette le tri et le filtres des datas */}
                <tr
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    backgroundColor: colorFilterAction,
                    borderTop: "none",
                    opacity: "0.90",
                  }}
                >
                  {/* Bouton qui permet de réinitialiser les filtres en supprimant les données du local storage et recharge la page */}
                  <th style={{ width: "100px", ...styles.tabLots }}>
                    <div style={{ display: "flex" }}>
                      <div>
                        <DownloadCSVFile data={data} />
                      </div>
                      <button
                        data-toggle="tooltip"
                        title={t("Reset filters")}
                        onClick={handleResetFilters}
                        className="btn bg-light text-hover-blue "
                        style={{ marginRight: "3px", marginLeft: "3px" }}
                      >
                        RAZ
                      </button>
                      <button
                        data-toggle="tooltip"
                        title={t("Reload page")}
                        className="btn bg-light"
                        onClick={() => window.location.reload(false)}
                      >
                        <FontAwesomeIcon
                          icon={faArrowRotateRight}
                          className="fa-copy"
                        />
                      </button>
                    </div>
                  </th>
                  {/* Select qui permet de choisir un modèle */}
                  <th style={styles.tabLots}>
                    {uniqueModels.length > 1 ? (
                      <select
                        className="form-select filter-select"
                        onChange={(e) =>
                          setFilter({ ...filter, sModelId: e.target.value })
                        }
                        style={{
                          width: "125px",
                          textAlign: "left",
                          height: "31px",
                        }}
                      >
                        <option value="">{t("Models")}</option>
                        {uniqueModels.map((model) => (
                          <option key={model} value={model}>
                            {model}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>{uniqueModels[0]}</span>
                    )}
                  </th>
                  {/* Input pour chercher une instance*/}
                  <th style={{ width: "300px", ...styles.tabLots }} colSpan="2">
                    {" "}
                    <input
                      type="text"
                      name="name"
                      placeholder={t("Instance name")}
                      className="form-control filter-input placeholder-font"
                      value={filter.searchInstance}
                      onChange={(e) =>
                        setFilter({ ...filter, searchInstance: e.target.value })
                      }
                      style={{ width: "300px", height: "31px" }}
                    />
                  </th>
                  {/* onclick qui permet de trier les dates par ordre croissant ou décroissant, et popup qui permet de trier en fonction de deux dates */}
                  <th
                    style={{ width: "100px", ...styles.tabLots }}
                    onClick={() => handleSort("dCreationDate")}
                  >
                    {sortField === "dCreationDate" &&
                    sortOrder === "asc" &&
                    data.some((item) => item.dCreationDate !== undefined) ? (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "5px" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </th>

                  {/* Select qui permet de choisir un status */}
                  <th style={styles.tabLots}>
                    {uniqueStatuses.length > 1 ? (
                      <select
                        className="form-select filter-select"
                        onChange={(e) =>
                          setFilter({ ...filter, sStatus: e.target.value })
                        }
                        style={{
                          width: "100px",
                          textAlign: "left",
                          height: "31px",
                        }}
                      >
                        <option value="">{t("Status")}</option>
                        {uniqueStatuses.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>{uniqueStatuses[0]}</span>
                    )}
                  </th>
                  {/* Input qui permet de chercher un client final*/}
                  <th style={{ width: "150px", ...styles.tabLots }}>
                    <input
                      type="text"
                      placeholder={t("End customer")}
                      className="form-control me-2 filter-input placeholder-font"
                      onChange={(e) =>
                        setFilter({ ...filter, sFinalCustomer: e.target.value })
                      }
                      style={{ width: "150px", height: "31px" }}
                    />
                  </th>
                  {/*onclick qui tri en ordre dsc ou crs les docs attendus*/}
                  <th
                    style={styles.tabLots}
                    onClick={() => handleSort("iExpectedDocs")}
                  >
                    {sortField === "iExpectedDocs" && sortOrder === "asc" ? (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "5px" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </th>
                  {/*onclick qui tri en ordre dsc ou crs les docs crées*/}
                  <th
                    style={styles.tabLots}
                    onClick={() => handleSort("iCreatedDocs")}
                  >
                    {sortField === "iCreatedDocs" && sortOrder === "asc" ? (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "5px" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </th>
                  {/*onclick qui tri en ordre dsc ou crs les docs uploadés*/}
                  <th
                    style={styles.tabLots}
                    onClick={() => handleSort("iUploadedDocs")}
                  >
                    {sortField === "iUploadedDocs" && sortOrder === "asc" ? (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "5px" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </th>
                  {/*onclick qui tri en ordre dsc ou crs les docs terminés*/}
                  <th
                    style={styles.tabLots}
                    onClick={() => handleSort("iCompletedDocs")}
                  >
                    {sortField === "iCompletedDocs" && sortOrder === "asc" ? (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "5px" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </th>
                </tr>
                {applySort(
                  filterByModel(
                    applyFilters(
                      data,
                      filter,
                      dateRangeFilter,
                      parseCustomDate
                    ),
                    filter
                  ),
                  sortField,
                  sortOrder
                )
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((item, index) => (
                    // ligne d'affichage des datas
                    <tr
                      key={item.kInstanceId || index}
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {/*Permet de download une instance*/}
                        <div
                          data-toogle="tooltip"
                          title={t("Download instance")}
                        >
                          <DownloadCSVDoc key={item.kInstanceId} item={item} />
                        </div>
                        {/*Permet d'accèder à une instance*/}
                        <button
                          data-toogle="tooltip"
                          title={t("View instance")}
                          className="btn btn-sm"
                          onClick={() => {
                            // Définir un cookie
                            Cookies.set("instanceId", item.kInstanceId);
                            Cookies.set("instanceName", item.sInstanceName);
                            navigate(`/Admin/Docs`);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faSearch}
                            className="fa-copy"
                          />
                        </button>
                      </td>
                      {/*Affichage du modelID*/}
                      <td style={{ width: "100px" }}>{item.sModelId}</td>
                      {/*Affichage de l'instance name */}
                      <td style={{ width: "300px" }}>
                        <span
                          style={{
                            //fontSize: "1.2em",
                            marginLeft: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {item.sInstanceName}
                        </span>
                      </td>
                      {/*Boutton pour copier l'instanceId */}
                      <td>
                        <CopyToClipboard
                          text={item.kInstanceId}
                          onCopy={() => handleCopy(item.kInstanceId)}
                        >
                          <button
                            className="btn btn-sm"
                            data-toogle="tooltip"
                            title={t("Copy instanceId")}
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </button>
                        </CopyToClipboard>
                      </td>
                      {/*Affichage de la date de création dans un format précis */}
                      <td>
                        {item.dCreationDate
                          ? formatCustomDate(item.dCreationDate)
                          : "Aucune date"}
                      </td>
                      {/*Affichage du status */}
                      <td style={{ width: "100px" }}>{item.sStatus}</td>
                      {/*Affichage du client final */}
                      <td>
                        <span
                          style={{
                            fontSize: "1.2em",
                            fontWeight: "bold",
                          }}
                        >
                          {item.sFinalCustomer
                            ? item.sFinalCustomer
                            : "Aucun client final"}
                        </span>
                      </td>
                      {/*Affichage des docs attendus*/}
                      <td>{item.iExpectedDocs}</td>
                      {/*Affichage des docs crées */}
                      <td>{item.iCreatedDocs}</td>
                      {/*Affichage des docs uploadés */}
                      <td>{item.iUploadedDocs}</td>
                      {/*Affichage des docs terminés */}
                      <td>{item.iCompletedDocs}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "10px",
              }}
            >
              {/*Affichage du nombre de pages en fonctions du nombre de docs*/}

              <Pagination
                filteredData={filteredData}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        ) : (
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              textAlign: "center",
            }}
          >
            Aucune instance dans votre modèle
          </h1>
        )}
      </div>
    </>
  );
};

export default AdminTabLotsBase;
